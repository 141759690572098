<template>
  <div>
    <canvas ref="chart" :width="size" :height="size"></canvas>
    <span v-if="innerNumber" class="inner-number">{{ innerNumber }}</span>
    <span v-if="innerText" class="inner-text">{{ innerText }}</span>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'Doughnut',
  props: {
    dataset: Array,
    colors: Array,
    size: {
      type: Number,
      default: 250
    },
    innerText: String,
    innerNumber: Number,
    // This determines the cutout percentage of doughnut
    thickness: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            data: this.dataset,
            backgroundColor: this.colors,
            borderWidth: 1,
            borderColor: '#000',
            borderAlign: 'inner'
          }
        ]
      },
      chartOption: {
        responsive: true,
        cutoutPercentage: 100 - this.thickness,
        tooltips: { enabled: false },
        hover: { mode: null },
        animation: {
          animateRotate: true
        }
      },
      chart: null,
      ctx: null
    }
  },
  methods: {
    randomScalingFactor() {
      return Math.round(Math.random() * 100)
    }
  },
  mounted() {
    this.ctx = this.$refs.chart.getContext('2d')
    this.chart = new Chart(this.ctx, {
      type: 'doughnut',
      data: this.chartData,
      options: this.chartOption
    })
  },
  watch: {
    dataset(val) {
      if (this.chart && val) {
        this.chart.update()
      }
    }
  }
}
</script>

<style scoped lang="scss">
div {
  position: relative;
  .inner-number {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    animation: blinking 1.5s infinite;
  }
  .inner-text {
    position: absolute;
    color: #333;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>
